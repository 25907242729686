.image-upload-container {
  margin: 20px auto;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 20px;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.input-error {
  border: 1px solid red;
}

.preview-image {
  width: 100%;
  height: auto;
  max-height: 300px; /* Adjust this value to control the maximum height of the preview */
  margin-top: 10px;
}
